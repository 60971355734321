var render = function render(){
  var _vm$extra, _vm$extra2, _vm$statusClass;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "breadcrumbs"
  }, [_c('div', {
    staticClass: "breadcrumbs__back cursor",
    on: {
      "click": _vm.go_back
    }
  }, [_c('img', {
    staticClass: "img_back",
    attrs: {
      "src": "/img/icons/breadcrumbs/arrow_back.svg",
      "alt": ""
    }
  }), _vm._v(" Назад ")]), _c('div', {
    staticClass: "breadcrumbs__title"
  }, [_vm._v(" " + _vm._s((_vm$extra = _vm.extra) === null || _vm$extra === void 0 ? void 0 : _vm$extra.name) + " ")]), _c('div', {
    staticClass: "breadcrumbs__extra d-flex"
  }, [_vm.extra ? [_c('div', {
    staticClass: "b-number"
  }, [_vm._v("№ " + _vm._s((_vm$extra2 = _vm.extra) === null || _vm$extra2 === void 0 ? void 0 : _vm$extra2.number))])] : _vm._e(), _vm.extra ? [_c('div', {
    staticClass: "b-date"
  }, [_vm._v(" " + _vm._s(_vm.type_name) + " ")])] : _vm._e(), _vm.extra ? [_c('div', {
    staticClass: "b-status",
    class: {
      success: _vm.statusClass.class
    }
  }, [_vm._v(" Статус: " + _vm._s((_vm$statusClass = _vm.statusClass) !== null && _vm$statusClass !== void 0 && _vm$statusClass.class ? 'активна' : 'выключена') + " ")])] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
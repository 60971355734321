var render = function render(){
  var _vm$device$branch, _vm$device$storage, _vm$device$entity;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('breadcrumbs', {
    attrs: {
      "extra": _vm.device
    }
  }), _c('input', {
    ref: "fileselector",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "webkitdirectory": "",
      "directory": "",
      "multiple": "false"
    },
    on: {
      "input": _vm.browseResult
    }
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "ml-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v(" Сохранить ")])], 1), _c('b-card', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticStyle: {
      "font-weight": "500",
      "font-size": "20px",
      "line-height": "24px",
      "color": "#bcbcbc",
      "margin-bottom": "18px"
    }
  }, [_vm._v(" Основные данные ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "row-item"
  }, [_c('label', [_vm._v("Название")]), _c('b-form-input', {
    model: {
      value: _vm.device.name,
      callback: function ($$v) {
        _vm.$set(_vm.device, "name", $$v);
      },
      expression: "device.name"
    }
  })], 1), _c('div', {
    staticClass: "row-item"
  }, [_c('label', [_vm._v("№ кассы")]), _c('b-form-input', {
    staticStyle: {
      "width": "60px"
    },
    model: {
      value: _vm.device.number,
      callback: function ($$v) {
        _vm.$set(_vm.device, "number", $$v);
      },
      expression: "device.number"
    }
  })], 1), _c('div', {
    staticClass: "row-item"
  }, [_c('label', [_vm._v("Магазин")]), _c('i-select', {
    attrs: {
      "value": (_vm$device$branch = _vm.device.branch) === null || _vm$device$branch === void 0 ? void 0 : _vm$device$branch.id,
      "placeholder": "Выберете магазин"
    },
    on: {
      "on-change": _vm.getStorage
    }
  }, _vm._l(_vm.branches, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "row-item"
  }, [_c('label', [_vm._v("Место хранения")]), _c('i-select', {
    attrs: {
      "value": (_vm$device$storage = _vm.device.storage) === null || _vm$device$storage === void 0 ? void 0 : _vm$device$storage.id
    },
    on: {
      "on-change": _vm.setStorage
    }
  }, _vm._l(_vm.storage_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "row-item"
  }, [_c('label', [_vm._v("Организация")]), _c('i-select', {
    attrs: {
      "value": (_vm$device$entity = _vm.device.entity) === null || _vm$device$entity === void 0 ? void 0 : _vm$device$entity.id
    },
    on: {
      "on-change": _vm.setEntity
    }
  }, _vm._l(_vm.entity_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)]), _c('div', {
    staticClass: "d-flex flex-column mt-3"
  }, [_c('div', {
    staticClass: "row-item"
  }, [_c('label', [_vm._v("Каталог загрузки")]), _c('e-input', {
    attrs: {
      "placeholder": "C:/in_path",
      "error": _vm.pathValidate(_vm.device.in_path)
    },
    model: {
      value: _vm.device.in_path,
      callback: function ($$v) {
        _vm.$set(_vm.device, "in_path", $$v);
      },
      expression: "device.in_path"
    }
  })], 1), _c('div', {
    staticClass: "row-item"
  }, [_c('label', [_vm._v("Каталог выгрузки")]), _c('e-input', {
    attrs: {
      "placeholder": "C:/out_path",
      "error": _vm.pathValidate(_vm.device.out_path)
    },
    model: {
      value: _vm.device.out_path,
      callback: function ($$v) {
        _vm.$set(_vm.device, "out_path", $$v);
      },
      expression: "device.out_path"
    }
  })], 1), _c('div', {
    staticClass: "row-item"
  }, [_c('label', [_vm._v("Каталог оперативной сводки")]), _c('e-input', {
    attrs: {
      "error": _vm.pathValidate(_vm.device.oper_path)
    },
    model: {
      value: _vm.device.oper_path,
      callback: function ($$v) {
        _vm.$set(_vm.device, "oper_path", $$v);
      },
      expression: "device.oper_path"
    }
  })], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
export const days_of_week = [
  {
    value: 'mon',
    label: 'Пн'
  },
  {
    value: 'tue',
    label: 'Вт'
  },
  {
    value: 'wed',
    label: 'Ср'
  },
  {
    value: 'thu',
    label: 'Чт'
  },
  {
    value: 'fri',
    label: 'Пт'
  },
  {
    value: 'sat',
    label: 'Сб'
  },
  {
    value: 'sun',
    label: 'Вс'
  }
]

export const week_of_month = [
  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  },
  {
    value: '3',
    label: '3'
  },
  {
    value: '4',
    label: '4'
  }
]

export const test_storelist = [
  {
    value: 'store_1',
    label: 'Магазин ГОВЯДИНА'
  },
  {
    value: 'store_2',
    label: 'Магазин СВИНЯТИНА'
  }
]

export const integration_map = {
  evotor: 'Эвотор',
  frontol: 'Frontol',
  dklink: 'DKLink'
}
export const operation_mapping = {
  income: 'Приход',
  bonus: 'Бонус',
  write_off: 'Списание',
  return: 'Возврат',
  cashless_sale: 'Б/Н продажа',
  purchase: 'Продажа',
  payback: 'Возврат',
  inventory: 'Инвентаризация',
  inventarisation: 'Инвентаризация',
  inventory_income: 'Инвентаризация',
  inventory_outgoing: 'Инвентаризация',
  movement: 'Перемещение',
  manufacture: 'Калькуляция',
  reverse_manufacture: 'Перефасовка'
}

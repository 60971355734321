<template>
  <div class="breadcrumbs">
    <div
      class="breadcrumbs__back cursor"
      @click="go_back"
    >
      <img
        class="img_back"
        src="/img/icons/breadcrumbs/arrow_back.svg"
        alt=""
      />
      Назад
    </div>
    <div class="breadcrumbs__title">
      {{ extra?.name }}
    </div>
    <div class="breadcrumbs__extra d-flex">
      <template v-if="extra">
        <div class="b-number">№ {{ extra?.number }}</div>
      </template>
      <template v-if="extra">
        <div class="b-date">
          {{ type_name }}
        </div>
      </template>
      <template v-if="extra">
        <div
          class="b-status"
          :class="{ success: statusClass.class }"
        >
          Статус:
          {{ statusClass?.class ? 'активна' : 'выключена' }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { integration_map } from '@/utils/constants'

  export default {
    props: {
      path: {
        type: Array,
        default: () => []
      },

      title: {
        type: String,
        default: null
      },

      extra: {
        type: Object,
        default: () => {}
      }
    },

    computed: {
      statusClass: function () {
        return {
          class: this.extra?.active
        }
      },
      page_title() {
        return this.title || this.$route.meta.title
      },
      type_name() {
        return integration_map[this.extra?.integration?.type]
      }
    },

    // data: function () {},

    methods: {
      convertDate(date) {
        if (date && date instanceof Date && !isNaN(date)) return formatDate(new Date(date), 'numeric')
        else return formatDate(new Date(), 'numeric')
      },

      go_back() {
        const backPage = this.$route.meta.parrent
        if (backPage) this.$router.push({ name: backPage })
        else this.$router.back()
      }
    }
  }
</script>

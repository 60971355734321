<template>
  <div class="d-flex flex-column">
    <breadcrumbs :extra="device" />
    <input
      ref="fileselector"
      type="file"
      webkitdirectory
      directory
      multiple="false"
      style="display: none"
      @input="browseResult"
    />
    <div class="d-flex">
      <b-button
        class="ml-auto"
        variant="primary"
        @click="update"
      >
        Сохранить
      </b-button>
    </div>

    <b-card
      no-body
      style="margin-top: 20px"
    >
      <div style="font-weight: 500; font-size: 20px; line-height: 24px; color: #bcbcbc; margin-bottom: 18px">
        Основные данные
      </div>

      <div class="d-flex">
        <div class="row-item">
          <label>Название</label>
          <b-form-input v-model="device.name" />
        </div>
        <div class="row-item">
          <label>№ кассы</label>
          <b-form-input
            v-model="device.number"
            style="width: 60px"
          />
        </div>
        <div class="row-item">
          <label>Магазин</label>
          <i-select
            :value="device.branch?.id"
            placeholder="Выберете магазин"
            @on-change="getStorage"
          >
            <i-option
              v-for="item in branches"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </i-select>
        </div>
        <div class="row-item">
          <label>Место хранения</label>
          <i-select
            :value="device.storage?.id"
            @on-change="setStorage"
          >
            <i-option
              v-for="item in storage_list"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </i-select>
        </div>

        <div class="row-item">
          <label>Организация</label>
          <i-select
            :value="device.entity?.id"
            @on-change="setEntity"
          >
            <i-option
              v-for="item in entity_list"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </i-select>
        </div>
      </div>
      <div class="d-flex flex-column mt-3">
        <div class="row-item">
          <label>Каталог загрузки</label>
          <e-input
            v-model="device.in_path"
            placeholder="C:/in_path"
            :error="pathValidate(device.in_path)"
          />
        </div>
        <div class="row-item">
          <label>Каталог выгрузки</label>
          <e-input
            v-model="device.out_path"
            placeholder="C:/out_path"
            :error="pathValidate(device.out_path)"
          />
        </div>
        <div class="row-item">
          <label>Каталог оперативной сводки</label>
          <e-input
            v-model="device.oper_path"
            :error="pathValidate(device.oper_path)"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import Breadcrumbs from '@/views/settings/components/devices/Breadcrumbs.vue'
  import { CashModel } from '@/models/cash.model'
  import { mapGetters } from 'vuex'
  import { BranchModel } from '@/models/branch.model'
  export default {
    name: 'Device',
    components: { Breadcrumbs },
    apollo: {
      Cash: {
        query: require('../gql/Cash.graphql'),
        variables() {
          return {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id
          }
        },
        result({ data }) {
          this.device = new CashModel(data.Cash)
          if (!this.device.branch?.id) this.device.branch = new BranchModel(this.currentBranch)
          this.getStorage(this.device.branch.id)
          if (this.entity_list.length === 1) this.device.entity = this.entity_list[0]
        }
      },

      Entities: {
        query: require('../gql/EntitiesEntity.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.entity_list = data.Entities || []
          if (this.entity_list.length === 1) this.device.entity = this.entity_list[0]
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    data() {
      return {
        device: new CashModel(),
        storage_list: [],
        entity_list: []
      }
    },

    computed: {
      ...mapGetters({
        branches: 'settings/getBranchList',
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    methods: {
      pathValidate(path) {
        if (!path) return
        return (
          !new RegExp('[a-zA-Z]:[\\\\\\/](?:[a-zA-Z0-9]+[\\\\\\/])*([a-zA-Z0-9]+)').test(path) &&
          !new RegExp('^/|(/[\\w-]+)+$').test(path)
        )
      },
      browseResult() {
        console.log(this.$refs.fileselector.value)
      },
      async getStorage(id) {
        this.device.branch = this.branches.find((el) => el.id === id)
        const { data } = await this.$apollo.query({
          query: require('../gql/getStorages.gql'),
          variables: { branch: id }
        })

        this.storage_list = data.Storages || []
      },
      update() {
        this.$apollo.mutate({
          mutation: require('../gql/updateCash.graphql'),
          variables: {
            input: this.device.input
          }
        })
      },
      setStorage(id) {
        this.device.storage = this.storage_list.find((el) => el.id === id)
      },

      setEntity(id) {
        this.device.entity = this.entity_list.find((el) => el.id === id)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .tags-header {
      background-color: transparent;
      border: none;
      padding-left: 0.625rem;
      margin-top: 30px;
    }
    .tag-item {
      background: #ffffff !important;
      border: 1px solid #e1e1e1 !important;
      border-radius: 100px !important;
      margin-right: 10px;
    }
    .tag-item-active {
      background: #00a3ff !important;
      margin-right: 16px;
    }
  }

  .row-item {
    margin-right: 18px;
  }
</style>
